
const initialState = {
    selectedExhibitor:null
  }
  
const searchBarReducer =  (state = initialState,action) => {
    switch(action.type){
        case 'GOTOEXHIBITOR':
            return Object.assign({},{selectedExhibitor:action.payload});
        default:
            return state;
    }
}

export default searchBarReducer;