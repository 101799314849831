

const initialState = {
    selectedParticipant:0,
    participantsOnStand:[],
    allUnreadMessages: {
     
    }


  }
  
const conversationReducer =  (state = initialState,action) => {
    switch(action.type){
        case 'ENTER_STAND':{
            //Agrego el id a las personas en el stand solo si no estan
            if (state.participantsOnStand.indexOf(action.payload.fromPersonId) == -1){
                return {
                    ...state,
                    participantsOnStand : [...state.participantsOnStand,action.payload.fromPersonId]
                
                };
            }
            else{
                return state;
            }
        }
        case 'LEAVE_STAND':{
            
            let alreadyInStand = state.participantsOnStand.filter( o => o != action.payload.fromPersonId) ||[];
            return {
                ...state,
                participantsOnStand : Array.from (alreadyInStand)
              
            };
        }
        case 'SELECT_PARTICIPANT':{
            const fromPersonId = Number(action.payload);
            return {
                ...state,
                selectedParticipant:fromPersonId,
                allUnreadMessages: {
                    ...state.allUnreadMessages,
                    [fromPersonId]: {
                        unreadMessagesCount:0,
                        unreadMessages:[]
                    }
                  }
              
            };
        }
        case 'MARK_AS_READ_ALL_MESSAGES_FROM':{
            const fromPersonId = action.payload;
            let unreadMessagesCount = 1;
            var obj = state.allUnreadMessages[fromPersonId];
            if (typeof obj != 'undefined'){
                return {
                    ...state,
                    allUnreadMessages: {
                        ...state.allUnreadMessages,
                        [fromPersonId]: {
                            unreadMessagesCount:0,
                           //Saco la lista de mensajes no leidos 
                            unreadMessages:[]
                        }
                      }
                };
            }
        }

        case 'RECEIVE_MESSAGE_FROM_SERVER':{
            //Incremento el counter para ese participante
            const messageFromServer = action.payload;
            const fromPersonId = messageFromServer.fromPersonId;
            let unreadMessagesCount = 1;
            var obj = state.allUnreadMessages[fromPersonId];
            if (typeof obj != 'undefined'){
                //Ya tengo ese usario en el store
                unreadMessagesCount = obj.unreadMessagesCount+1;
                return {
                    ...state,
                    allUnreadMessages: {
                        ...state.allUnreadMessages,
                        [fromPersonId]: {
                            unreadMessagesCount:unreadMessagesCount,
                            unreadMessages:[...state.allUnreadMessages[fromPersonId].unreadMessages,action.payload]
                        }
                      }
                };
            }
            else{
                return {
                    ...state,
                    allUnreadMessages: {
                        ...state.allUnreadMessages,
                        [fromPersonId]: {
                            unreadMessagesCount:1,
                            unreadMessages:[action.payload]
                        }
                      }
                  
                };
            }
          
        }
        default:
            return state;
    }
}

export default conversationReducer;