
const initialState = {
    liveFeed: []


  }
  
const liveFeedReducer =  (state = initialState,action) => {
    switch(action.type){
        case 'LIVEFEED_MESSAGE_RECEIVED':{
            return { 
                ...state,
                liveFeed: state.liveFeed.concat(action.payload)
            }
          
        }
        case 'LIVEFEED_CLEAR':{
            return { 
                ...state,
                liveFeed: []
            }
          
        }
     
       
        default:
            return state;
    }
}

export default liveFeedReducer;