import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getCurrentTime } from '../../helpers/Utils';
import axios from 'axios';
import {__APIKCURL__} from '../../consts/consts';
import Storage from '../../components/Storage';


import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONVERSATIONS,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_CHANGE_CONVERSATION
} from '../actionsTypes';

import {
  getContactsSuccess,
  getContactsError,
  getConversationsSuccess,
  getConversationsError,
} from './actions';

import contactsData from '../../data/chat.contacts.json';
import conversationsData from '../../data/chat.conversations.json';
import Utils from '../../components/Utils';

function* loadContacts() {
  try {
		const options = {
			url: __APIKCURL__+'Attendees/',
			method: 'POST',
			headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json;charset=UTF-8'
			},
			data: {
			idEvent: Storage.getIdEvent(),
			networkingAction: "GetAttendeeChatList",
			registrationCode:  Storage.getPersonRegistrationCode()
			}
		};
	
    const response = yield call(() => axios(options));
    //const response = yield call(loadContactsAsync);
    const contacts = response.data;
    const currentUser = Storage.getMyUser();
    yield put(getContactsSuccess(contacts, currentUser));
  } catch (error) {
    yield put(getContactsError(error));
  }
}

const loadContactsAsync = async () => {
  const contacts = contactsData.data;
  const currentUser = contacts[0];
  return await new Promise((success, fail) => {
    setTimeout(() => {
      success({ contacts, currentUser });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* loadConversations(userId) {
  try {
    const response = yield call(loadConversationsAsync, userId);
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const loadConversationsAsync = async ({ payload }) => {
  const selectedUser =  payload;
  const options = {
   url: __APIKCURL__+'Attendees/',
   method: 'POST',
   headers: {
     'Accept': 'application/json',
     'Content-Type': 'application/json;charset=UTF-8'
   },
   data: {
     
     idEvent: Storage.getIdEvent(),
     networkingAction: "GetMessagesBetweenUsers",
     registrationCode: Storage.getPersonRegistrationCode(),
     contactedPersonId: selectedUser
   }
 };

  
  return await new Promise((success, fail) => {
    let response =  axios(options);
    let conversations = response.data.map(result => {
      
        let dateTotransform = new Date(result.postedDate);
        return {
          id: result.id,
          message: result.message,
          author:Number(result.postedByPersonId),
          timestamp:dateTotransform.setHours(dateTotransform.getHours() - 3),
          confirmed:true
        };
      });
      success({ conversations, selectedUser });
    })
    .then((response) => response)
    .catch((error) => error);
};

function* addMessageToConversation(socket,{ payload }) {
  try {
    const {
      currentUserId,
      selectedUserId,
      message,
      allConversations,
    } = payload;

    const response = yield call(
      addMessageToConversationAsync,
      socket,
      currentUserId,
      selectedUserId,
      message,
      allConversations
    );
    const { conversation, selectedUser } = response;
    yield put(getConversationsSuccess(conversation, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}
const addMessageToConversationAsync = async (
  socket,
  currentUserId,
  selectedUserId,
  message,
  conversation
) => {
  socket.sendMessage(selectedUserId,message,'123122');
 
  const time = getCurrentTime();
  if (conversation) {
    conversation.messages.push({
      sender: currentUserId,
      time,
      text: message,
    });
    conversation.lastMessageTime = time;

    return await new Promise((success, fail) => {
      setTimeout(() => {
        success({ conversation, selectedUser: selectedUserId });
      }, 500);
    })
      .then((response) => response)
      .catch((error) => error);
  }
};
function* changeConversation({ payload }) {
  try {
   const response = yield call(loadConversationsAsync, payload);
   const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

function* createNewConversation({ payload }) {
  try {
    const { currentUserId, selectedUserId, allConversations } = payload;
    const response = yield call(
      createNewConversationAsync,
      currentUserId,
      selectedUserId,
      allConversations
    );
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const createNewConversationAsync = async (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  const conversation = {
    id: allConversations.length + 1,
    users: [currentUserId, selectedUserId],
    lastMessageTime: '-',
    messages: [],
  };

  allConversations.splice(0, 0, conversation);
  return await new Promise((success, fail) => {
    setTimeout(() => {
      success({
        conversations: allConversations,
        selectedUser: selectedUserId,
      });
    }, 500);
  })
    .then((response) => response)
    .catch((error) => error);
};

export function* watchGetContact() {
  yield takeEvery(CHAT_GET_CONTACTS, loadContacts);
}
export function* watchGetConversation() {
  yield takeEvery(CHAT_GET_CONVERSATIONS, loadConversations);
}
export function* watchAddMessageToConversation(socket) {
  yield takeEvery(CHAT_ADD_MESSAGE_TO_CONVERSATION, addMessageToConversation,socket);
}
export function* watchCreateConversation() {
  yield takeEvery(CHAT_CREATE_CONVERSATION, createNewConversation);
}
export function* watchChangeConversation() {
  yield takeEvery(CHAT_CHANGE_CONVERSATION, changeConversation);
}

export default function* rootSaga(socket) {
  yield all([
    fork(watchGetContact),
    fork(watchGetConversation),
    fork(watchAddMessageToConversation,socket),
    fork(watchCreateConversation),
    fork(watchChangeConversation),
  ]);
}
