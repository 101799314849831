
const initialState = {
    certificateCreated: false,
    certificateUrl:''


  }
  
const certificateReducer =  (state = initialState,action) => {
    switch(action.type){
        case 'CERTIFICATE_CREATED':{
            return { 
                ...state,
                certificateCreated: true,
                certificateUrl:action.payload
            }
          
        }
       
       
        default:
            return state;
    }
}

export default certificateReducer;