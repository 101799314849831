
const initialState = {
    notifications: {},
    notificationsUnread:0

  }
  
const notificationCenterReducer =  (state = initialState,action) => {
    switch(action.type){
        case 'NOTIFICATION_RECEIVED':{

            return Object.assign({}, state, {
                notifications: action.payload,
                notificationsUnread:state.notificationsUnread + 1
              })
        }
        //Cuando se oculta o cierro el toast, tengo que sacarla
        case 'NOTIFICATION_READED':{
            return Object.assign({}, state, {
                notifications: {}
              })
        }
       
        //Cuando toco la barra de navegación hago un clear
        case 'CLEAR_NOTIFICATION_COUNTER':{
            return Object.assign({}, state, {
                notificationsUnread:0
              })
        }
       
        default:
            return state;
    }
}

export default notificationCenterReducer;