
import {__DOMAIN__,__IDEVENT_FORCED__,__SHOW_SPLASH_VIDEO__} from '../consts/consts';
import Cookies from 'universal-cookie';
import Utils from './Utils';
class Storage {
	static saveStands (stands) 
	{
        localStorage.setItem('stands',stands);
	}

	static saveFloorPlans(floorPlans)
	{
		localStorage.setItem('floorPlans',floorPlans);
	}
	static getFloorPlans()
	{
		return JSON.parse(localStorage.getItem('floorPlans'))
				.filter(o => o.deleted == 0)
				.sort((a,b) => a.order - b.order)

	}
	
	static getCurrentLanguage()
	{
		//Este metodo no tiene set porque lo pone solo gogo
		return localStorage.getItem('currentLanguage');
	}

	static getMyUser(){
		return  {firstName:Storage.getPersonName(),lastName:Storage.getPersonLastname(),idPerson:Storage.getIdPerson()}
	}

	static  getStands (){
        return JSON.parse(localStorage.getItem('stands'));
    }
    static 	getPavilionMap (pavilionUrl){
		
		var stands =  JSON.parse(localStorage.getItem('stands')).filter(element => element.pavilionUrl === pavilionUrl);
        return {
          name: "my-map",
          areas:  stands.map( s =>
            {
              return{
                name: s.exhibitorName,
                shape:"rect",
                coords:s.standPositionOnFloorplan,
                preFillColor:"transparent", 
                fillColor: "transparent"
              }
            })
        }
	}

	static 	getNewPavilionMap (pavilionUrl){
		
		var stands =  JSON.parse(localStorage.getItem('stands')).filter(element => element.pavilionUrl === pavilionUrl);
		return stands.map( s =>
            {
              return{
                name: s.exhibitorName,
                coords:s.standPositionOnFloorplan,
				tooltip:s.exhibitorName,
				actionType:"internalLink",
				imageUrl:"https://kingconfimages.s3.amazonaws.com/88138f7b-ba52-35b9-328e-12fc7a582059_1pxtransparent.png",
				actionPayload:"/fair/stand/"+encodeURI(s.exhibitorName)
              }
            })
	}
	
	static findExhibitor(exhibitorName){
		let exhibitorNameDecoded =  decodeURI(exhibitorName);
		let standSeleccionado = Storage.getStands().find(element => element.exhibitorName.toLowerCase() === exhibitorNameDecoded.toLowerCase());
		return standSeleccionado;
	}

	static getPavilions (){
		
		var stands =  JSON.parse(localStorage.getItem('stands'));
		var standsWithDifferentPavilion = [...new Map(stands.map(item => [item['pavilionName'], item])).values()]//Con esto me quedo las categorias con disctintc
		var floorPlans =  Storage.getFloorPlans();
		var pavilions =  standsWithDifferentPavilion.map( e =>
			{
				let floorPlan = floorPlans.find(f=>f.name == e.pavilionName);
				let order = 0;
				if (floorPlan == null){
					alert('El floorplan del pabellón '+e.pavilionName+" no existe. El expositor "+e.exhibitorName+" debe estar asociado a un pabellón con un floorplan existente");
				}
				else{
					order = floorPlan.order;
				}
				return {
					pavilionName:e.pavilionName,
					pavilionUrl:e.pavilionUrl,
					floorPlanType:floorPlan.floorPlanType,
					order:order
				}
			}

		)
		return pavilions.sort((a,b) => a.order == b.order?a.floorPlanType - b.floorPlanType:a.order - b.order);
	}
	static getMyStandToRepresent(){
		var idPerson = Storage.getIdPerson();
		var stands =  JSON.parse(localStorage.getItem('stands'));
		if (stands == null){
			throw "";
		}
		return stands.find( o => o.idPersonExhibitor == idPerson);
	}
	static clearAll(){
		const cookies = new Cookies();
		cookies.remove('jwtToken', {path: "/", domain: __DOMAIN__});
		window.localStorage.clear();
  	}
	  static storeCredentials(jwtToken,registrationType,idPerson){
		window.localStorage.setItem('registrationType',registrationType);
		window.localStorage.setItem('isAuthenticated',true);
		window.localStorage.setItem('idPerson',idPerson);
		window.localStorage.setItem('jwtToken',jwtToken); //Guardo por las dudas que no me deje guardar cookies
		const cookies = new Cookies();
		cookies.set('jwtToken', jwtToken,  {path: "/", domain: __DOMAIN__});

	}
	
	static getJwtToken (){
		const cookies = new Cookies();
		let jwtToken = typeof (cookies.get('jwtToken')) != undefined ? cookies.get('jwtToken'):'';
		if (jwtToken == '' || typeof (cookies.get('jwtToken')) == "undefined"){
			jwtToken =window.localStorage.getItem('jwtToken');
		}
		return jwtToken;
	}
	static getIsAuthenticated (){
		return window.localStorage.getItem('isAuthenticated');
	}
	
	static getRegistrationType (){
		return window.localStorage.getItem('registrationType');
	}
	static getIdPerson (){
		return window.localStorage.getItem('idPerson');
	}
	static getIdRegistration (){
		return window.localStorage.getItem('idRegistration');
	}
	static setAudioPlayingTime(time){
		window.localStorage.setItem('audioPlayingTime',time);
	}
	static getAudioPlayingTime(time){
		return window.localStorage.getItem('audioPlayingTime');
	}
	static setAudioPlayingTimeLogin(time){
		window.localStorage.setItem('audioPlayingTimeLogin',time);
	}
	static getAudioPlayingTimeLogin(time){
		return window.localStorage.getItem('audioPlayingTimeLogin');
	}
	static setProgramme(sessions,speakers,activityTracks,activityTypes){
		window.localStorage.setItem('sessions',JSON.stringify(sessions));
		window.localStorage.setItem('speakers',JSON.stringify(speakers));
		window.localStorage.setItem('activityTracks',JSON.stringify(activityTracks));
		window.localStorage.setItem('activityTypes',JSON.stringify(activityTypes));
	}

	static getProgramme(){
		return {
			sessions : JSON.parse( window.localStorage.getItem('sessions')),
			speakers : JSON.parse( window.localStorage.getItem('speakers')),
			activityTracks : JSON.parse( window.localStorage.getItem('activityTracks')),
			activityTypes : JSON.parse( window.localStorage.getItem('activityTypes'))
		}
	}

	static clearProgramme(){
		window.localStorage.setItem('sessions',null);
		window.localStorage.setItem('speakers',null);
		window.localStorage.setItem('activityTracks',null);
		window.localStorage.setItem('activityTypes',null);
	}


	/*GEC: Methods for handling menu from api call instead of hardcode.*/
	/*	   Object needs to be stringyfied  and then parsed for this to work. 
			Also, at least ONE OPTION MUST EXIST otherwise it will fail.*/  
	// static storeVirtualMenu(){
	// 	
	// 	var menu = [  
	// 		{
	// 			id: 'home',
	// 			icon: 'simple-icon-home',
	// 			label: 'menu.home',
	// 			to: '/',
	// 	  	}
	// 	]
	// 	window.localStorage.setItem('virtualMenu',JSON.stringify(menu));
	// }

	// static getVirtualMenu(){
	// 	
	// 	return JSON.parse(window.localStorage.getItem('virtualMenu'));

	// }

	static storeLoginInfo(registrationCode,email,name,lastname,idPicture,idRegistration,tags,additionalInformation){
		window.localStorage.setItem('registrationCode',registrationCode);
		window.localStorage.setItem('idRegistration',idRegistration);
        window.localStorage.setItem('email',email);
        window.localStorage.setItem('name',name);
		window.localStorage.setItem('lastname',lastname);
		window.localStorage.setItem('idPicture',Math.abs(idPicture));
		if (tags != null)
			window.localStorage.setItem('tags',tags);
		if (additionalInformation != null)
			window.localStorage.setItem('additionalInformation',additionalInformation);
	}

	static saveRoomsInfo(roomsArray){
		window.localStorage.setItem('roomsArray',JSON.stringify(roomsArray));
	}

	static getRoomsInfo(){
		return JSON.parse(window.localStorage.getItem('roomsArray'))
	}

	static saveEventBasicInfo(virtualBasicInfo){
		window.localStorage.setItem('loginPageBackgroundPictureId',virtualBasicInfo.virtualEventLoginPageBackgroundPictureId);
		window.localStorage.setItem('loginFormBackgroundPictureId',virtualBasicInfo.VirtualEventLoginFormBackgroundPictureId);
		window.localStorage.setItem('outsideVenuePictureId',virtualBasicInfo.VirtualExhibitionOutsideVenuePictureId);
		window.localStorage.setItem('logoPictureId',virtualBasicInfo.VirtualEventLogoPictureId);
		window.localStorage.setItem('logoPictureId',virtualBasicInfo.VirtualEventLogoPictureId);
		window.localStorage.setItem('eventTitle', virtualBasicInfo.title);
		window.localStorage.setItem('timeZone', virtualBasicInfo.timeZoneId);
		let menuJson = JSON.parse(virtualBasicInfo.virtualMenuJson);
		window.localStorage.setItem('mainScheduleNr',menuJson.mainScheduleNr);
		window.localStorage.setItem('abstractScheduleNr',menuJson.abstractScheduleNr);
		window.localStorage.setItem('showPeopleLastNameFirst',menuJson.showPeopleLastNameFirst);
		window.localStorage.setItem('orderPeopleByLastNameFirst',menuJson.orderPeopleByLastNameFirst);
		let menuConverted = Utils.convertMenu(menuJson.menuButtonStoreData);
		window.localStorage.setItem("menu", JSON.stringify(menuConverted))

	}
	static recreateMenu(){
		let menuJson =JSON.parse(window.localStorage.getItem("menu"));
		let menuConverted = Utils.convertMenu(menuJson);
		window.localStorage.setItem("menu", JSON.stringify(menuConverted))
	}

	static getTimeZone(){
		return window.localStorage.getItem('timeZone');
	}

	static getMenu(){
		return JSON.parse(window.localStorage.getItem("menu"));
	}
	
	static getAbstractScheduleNr(){
		return window.localStorage.getItem("abstractScheduleNr");
	}

	static getMainScheduleNr(){
		return window.localStorage.getItem("mainScheduleNr");
	}

	static getEventLogoPictureId(){
		return window.localStorage.getItem("logoPictureId");
	}
	static getEventTitle(){
		return window.localStorage.getItem('eventTitle') != null? window.localStorage.getItem('eventTitle') :'';
	}

	static getOutsideVenuePictureId(){
		return window.localStorage.getItem("outsideVenuePictureId");
	}

	static getLoginPageBackgroundPictureId(){
		return window.localStorage.getItem("loginPageBackgroundPictureId");
		
	}

	static getLoginFormBackgroundPictureId(){
		return window.localStorage.getItem("loginFormBackgroundPictureId");
	}
	
	static getShowPeopleLastNameFirst(){
		return window.localStorage.getItem("showPeopleLastNameFirst") == "true"
	}

	static getOrderPeopleByLastNameFirst(){
		return window.localStorage.getItem("orderPeopleByLastNameFirst") == "true"
	}

	static getPersonRegistrationCode (){
		return window.localStorage.getItem('registrationCode');
	}

	static getPersonTags (){
		return window.localStorage.getItem('tags');
	}
	static getPersonAdditionalInformation (){
		return window.localStorage.getItem('additionalInformation');
	}
	static getPersonEmail (){
		return window.localStorage.getItem('email');
	}
	static getPersonName (){
		return window.localStorage.getItem('name');
	}
	static getPersonLastname (){
		return window.localStorage.getItem('lastname');
	}
	static getPersonCompletename (){
		return window.localStorage.getItem('name')+ ' '+ window.localStorage.getItem('lastname');
	}
	static getIdPicture (){
		return window.localStorage.getItem('idPicture');
	}


	static getIdEvent(){
		let urlParams = new URLSearchParams(window.location.search);
		let idEventUrl = urlParams.get('AcEventId');
		let idEvent =   window.localStorage.getItem('AcEventId');
		if (idEvent == null || (__IDEVENT_FORCED__ != idEvent && __IDEVENT_FORCED__ != 0)
		|| (idEventUrl != null && __IDEVENT_FORCED__ != idEventUrl)){
			Storage.clearAll();
			Storage.setIdEvent();
			window.location.href = "/";
		}
		return  window.localStorage.getItem('AcEventId');
	}
	static setIdEvent(){
		if (__IDEVENT_FORCED__ != 0){
			window.localStorage.setItem('AcEventId',__IDEVENT_FORCED__);
		}
		else{
			let urlParams = new URLSearchParams(window.location.search);
			let acEventId = urlParams.get('AcEventId');
			if (acEventId == null)
				throw "Id del evento no seteado";
			else
			window.localStorage.setItem('AcEventId',acEventId);
		}
	}

	static getWelcomeVideoPlayed (){
		return (__SHOW_SPLASH_VIDEO__ == true && (window.localStorage.getItem('WelcomeVideoPlayed') == null || 
				window.localStorage.getItem('WelcomeVideoPlayed') == 'false'))
		?false:true;
	}
	static setWelcomeVideoPlayed (value){

		window.localStorage.setItem('WelcomeVideoPlayed',value == null?true:value);
	}

	static overrideMyVideoConferenceConfiguration(link){
		//Escribo esto en el localstorage para que no tenga que volver a cargar los stands
		window.localStorage.setItem('videoConferenceConfiguration',link);
	}

	static getMyVideoConferenceConfiguration(){
		var videolink= window.localStorage.getItem('videoConferenceConfiguration');
		if (videolink != null){
			return videolink;
		}
		else{
			var myStand = Storage.getMyStandToRepresent();
			if (myStand != null){
				return myStand.videoConferenceConfiguration;
			}
		}
		return null;
	}

	static setLanguageOverrideEs(languageOverrideEs){
		window.localStorage.setItem('LanguageOverrideEs',JSON.stringify(languageOverrideEs));
	}
	static getLanguageOverrideEs(){
		return JSON.parse(window.localStorage.getItem('LanguageOverrideEs'));
	}
	static setLanguageOverrideEn(languageOverrideEn){
		window.localStorage.setItem('LanguageOverrideEn',JSON.stringify(languageOverrideEn));
	}

	static getLanguageOverrideEn(languageOverrideEn){
		return JSON.parse(window.localStorage.getItem('LanguageOverrideEn'));
	}
	static setLanguageOverridePt(languageOverridePt){
		window.localStorage.setItem('LanguageOverridePt',JSON.stringify(languageOverridePt));
	}
	static getLanguageOverridePt(languageOverridePt){
		return JSON.parse(window.localStorage.getItem('LanguageOverridePt'));
	}
	static setLanguageOverrideFr(languageOverrideFr){
		window.localStorage.setItem('LanguageOverrideFr',JSON.stringify(languageOverrideFr));
	}
	static getLanguageOverrideFr(languageOverrideFr){
		return JSON.parse(window.localStorage.getItem('LanguageOverrideFr'));
	}
	static setStartupPage(startupPage){
		window.localStorage.setItem('StartupPage',startupPage);
	}
	static getStartupPage(startupPage){
		return window.localStorage.getItem('StartupPage');
	}

	static setPromoCode(promoCode){
		window.localStorage.setItem('PromoCode',promoCode);
	}
	static getPromoCode(){
		return window.localStorage.getItem('PromoCode');
	}


}
export default Storage;